import { render, staticRenderFns } from "./CurationsForm.vue?vue&type=template&id=17164600&scoped=true"
import script from "./CurationsForm.vue?vue&type=script&lang=js"
export * from "./CurationsForm.vue?vue&type=script&lang=js"
import style0 from "./CurationsForm.vue?vue&type=style&index=0&id=17164600&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17164600",
  null
  
)

export default component.exports